import React from 'react';
import { Link, useParams } from 'react-router-dom';
import styled from 'styled-components';
import TeamLineups from './TeamLineups';
import TEAM_ATTRIBUTES from '../constants/teams';

const TeamNav = styled.nav`
  display: flex;
  align-items: center;

  a {
    width: ${100 / TEAM_ATTRIBUTES.length}%;
  }

  svg {
    margin: 5px;
  }
`;

const TeamLineup = styled.div`
  width: 100%;
  margin: auto;
  text-align: center;

  @media only screen and (min-width: 1280px) {
    max-width: 768px;
  }
`;

const renderTeamLinks = (gameData) =>
  TEAM_ATTRIBUTES.map((team) => (
    <Link to={`/kokoonpanot/${team.key}`} key={team.key}>
      {team.logo}
    </Link>
  ));
function Lineups({ gameData }) {
  let { team } = useParams();
  let teamLineup;
  let headerText = 'Kokoonpanot - Valitse joukkue';
  if (team) {
    const teamAttributes = TEAM_ATTRIBUTES.find((t) => t.key === team);
    headerText = `Kokoonpanot - ${teamAttributes.name}`;
    teamLineup = <TeamLineups gameData={gameData} team={teamAttributes} />;
  }
  return (
    <div>
      <h1>{headerText}</h1>
      <TeamNav>{renderTeamLinks(gameData)}</TeamNav>
      <TeamLineup>{teamLineup}</TeamLineup>
    </div>
  );
}
export default Lineups;
