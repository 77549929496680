import React from 'react';

const Jersey = ({
  number,
  name,
  collarColor,
  shouldersColor,
  sleevesColor,
  topBackColor,
  bottomBackColor,
  smallStripesColor,
  largeStripesColor,
  textColor,
  textStrokeColor,
}) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 521.211 618.214'>
    <path fill={topBackColor} stroke='#000' d='M260.605 107.715l-190 20 20 160v189.999h340v-190l20-160z' />
    <g fill={smallStripesColor} stroke='#000'>
      <path d='M90.605 477.714h340v20h-340z' />
      <path d='M90.605 537.714v20h340v-20z' />
      <path d='M520.543 447.714c.099 6.676.155 13.346.166 20h-90.104v-20z' />
      <path d='M518.55 387.714c.338 6.65.64 13.319.903 20h-88.848v-20z' />
      <path d='M.668 447.714c-.099 6.676-.155 13.346-.166 20h90.103v-20z' />
      <path d='M2.66 387.714c-.338 6.65-.64 13.319-.902 20h88.847v-20z' />
    </g>
    <path
      fill={bottomBackColor}
      stroke='#000'
      d='M260.605 617.714c49.632-.04 156.413-8.832 170-20 11.138-9.155 1.93-18.855 0-20v-20h-170m0 60c-49.632-.04-156.412-8.832-170-20-11.137-9.155-1.93-18.855 0-20v-20h170'
    />
    <g fill={sleevesColor} stroke='#000'>
      <path d='M460.605 67.715l-10 60-20 160v100h87.985c-.168-3.34-.345-6.672-.531-10h-.045c-8.78-155.583-37.17-297.605-57.409-310zm-30 400v110c15.736 10.473 85.092 6.352 86.409-9.834 2.616-32.168 3.75-65.961 3.695-100.166z' />
      <path d='M60.605 67.714c-20.239 12.395-48.629 154.417-57.408 310-.188 3.328-.367 6.66-.537 10h87.945v-100l-20-160zm-60.103 400C.447 501.919 1.58 535.712 4.197 567.88c1.317 16.186 70.673 20.307 86.408 9.834v-110z' />
    </g>
    <g fill={largeStripesColor} stroke='#000'>
      <path d='M1.758 407.714a1638.765 1638.765 0 00-1.09 40h89.937v-40z' />
      <path d='M90.605 497.714h340v40h-340z' />
      <path d='M519.453 407.714a1638.8 1638.8 0 011.09 40h-89.938v-40z' />
    </g>
    <path
      fill={shouldersColor}
      stroke='#000'
      d='M172.041 12.52C90.052 35.726 60.605 67.715 60.605 67.715l10 60 190-20 190 20 10-60s-29.445-31.987-111.43-55.191C286.94 34.42 217.354 27.346 172.042 12.52z'
    />
    <path
      fill={collarColor}
      stroke='#000'
      d='M172.041 12.52c-1.41-.476 12.827-14.198 18.564-11.712 16.17 7.008 52.491 9.74 69.937 9.299 17.446.44 53.904-2.291 70.075-9.3 5.737-2.485 19.974 11.237 18.564 11.714-62.239 21.9-131.827 14.826-177.14 0z'
    />
    <text
      x='260'
      y='445'
      fill={textColor}
      stroke={textStrokeColor}
      strokeLinejoin='bevel'
      strokeWidth='4'
      fontFamily='HaettenschweilerLocal'
      fontSize='290'
      fontWeight='400'
      letterSpacing='10'
      wordSpacing='0'
      textAnchor='middle'
    >
      {number}
    </text>
    <text
      x='260'
      y='210'
      fill={textColor}
      stroke={textStrokeColor}
      strokeWidth='2'
      fontFamily='HaettenschweilerLocal'
      fontSize='80'
      fontWeight='400'
      letterSpacing='0'
      wordSpacing='0'
      textAnchor='middle'
    >
      {name.toUpperCase()}
    </text>
  </svg>
);

export default Jersey;
