export { default as ASSAT } from './assat.svg';
export { default as HIFK } from './hifk.svg';
export { default as HPK } from './hpk.svg';
export { default as ILVES } from './ilves.svg';
export { default as JUKURIT } from './jukurit.svg';
export { default as JYP } from './jyp.svg';
export { default as KALPA } from './kalpa.svg';
export { default as KESPOO } from './k-espoo.svg';
export { default as KARPAT } from './karpat.svg';
export { default as KOOKOO } from './kookoo.svg';
export { default as LUKKO } from './lukko.svg';
export { default as PELICANS } from './pelicans.svg';
export { default as SAIPA } from './saipa.svg';
export { default as SPORT } from './sport.svg';
export { default as TAPPARA } from './tappara.svg';
export { default as TPS } from './tps.svg';
