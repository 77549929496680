import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import Spinner from './Spinner';
import Player from './Player';

const LastUpdateInfo = styled.div`
  font-style: italic;
`;

const LineTitle = styled.div`
  font-size: 2em;
  padding: 0.5em;
`;

const LineupWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1.2em;
`;
const ForwardsWrapper = styled.div`
  flex: 1;
  column-count: 3;
  width: 100%;
`;

const DefensemanWrapper = styled.div`
  flex: 1;
  column-count: 2;
  width: calc(2 / 3 * 100%);
`;

function findLatestLineupForTeam(gameData, team) {
  const lastGameForTeam = gameData
    .sort((a, b) => moment(a.date).diff(moment(b.date)))
    .find((game) => (game.home === team.name && game.homeLineup.length > 0) || (game.away === team.name && game.awayLineup.length > 0));
  
    return lastGameForTeam
    ? {
        date: lastGameForTeam.date,
        lineup: lastGameForTeam.home === team.name ? lastGameForTeam.homeLineup : lastGameForTeam.awayLineup,
      }
    : null;
}

const renderPlayers = (team, players) => players.map((player) => <Player key={player.number} number={player.number} name={player.name} team={team} />);

const renderGoalies = (team, lineup) => {
  const goalies = lineup.filter((line) => line.name === 'Maalivahdit')[0].players;
  return (
    <LineupWrapper>
      <LineTitle>Maalivahdit</LineTitle>
      <DefensemanWrapper>{renderPlayers(team, goalies)}</DefensemanWrapper>
    </LineupWrapper>
  );
};

const renderLine = (team, line) => {
  const forwards = line.players.filter((player) => player.position === 'f');
  const defenceman = line.players.filter((player) => player.position === 'd');
  return (
    <LineupWrapper key={line.name}>
      <LineTitle>{line.name}</LineTitle>
      <ForwardsWrapper>{renderPlayers(team, forwards)}</ForwardsWrapper>
      <DefensemanWrapper>{renderPlayers(team, defenceman)}</DefensemanWrapper>
    </LineupWrapper>
  );
};

const renderLines = (team, lineup) => lineup.filter((line) => line.name !== 'Maalivahdit').map((line) => renderLine(team, line));

const renderLineup = (team, lineup) => (
  <div>
    {renderLines(team, lineup)}
    {renderGoalies(team, lineup)}
  </div>
);

const TeamLineups = ({ gameData, team }) => {
  let lineupContent = <Spinner />;
  let lastUpdated;

  if (gameData.length > 0) {
    const latestLineup = findLatestLineupForTeam(gameData, team);
    lineupContent = latestLineup ? renderLineup(team, latestLineup.lineup) : <div>Ei kokoonpanotietoja saatavilla</div>;
    lastUpdated = latestLineup ? moment(latestLineup.date).format('D.MM.YYYY') : moment().format('D.MM.YYYY');
  }

  return (
    <div>
      <LastUpdateInfo>{`Viimeksi päivitetty ${lastUpdated}`}</LastUpdateInfo>
      {lineupContent}
    </div>
  );
};
export default TeamLineups;
