import React, { useState, useEffect } from 'react';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import styled from 'styled-components';
import DateRange from './DateRange';
import Spinner from './Spinner';
import GameCalendar from './GameGalendar';
import TEAM_ATTRIBUTES from '../constants/teams';

const moment = extendMoment(Moment);

const ContentWrapper = styled.div`
  > * {
    margin: 20px 0;
  }
`;
function Schedule({ gameData }) {
  const DAY_FORMAT = 'YYYYMMDD';
  const today = moment();
  const daysToShow = Math.floor(Math.min(window.innerWidth, 1280) / 80) - 3;
  const endDay = moment().add(daysToShow, 'days');
  const [from, setFrom] = useState(today.format(DAY_FORMAT));
  const [to, setTo] = useState(endDay.format(DAY_FORMAT));
  const [games, setGames] = useState([]);
  const [dates, setDates] = useState([]);

  useEffect(() => {
    if (gameData) {
      const dateRangeGamesPerTeam = [];
      const dateRange = moment.range(from, to);
      const dateRangeArray = Array.from(dateRange.by('days'));
      const dateRangeDates = dateRangeArray.map((date) => date.format(DAY_FORMAT));
      const dateRangeAllGames = gameData.filter((game) => dateRange.contains(moment(game.date)));

      TEAM_ATTRIBUTES.forEach((team, index) => {
        dateRangeGamesPerTeam.push({ ...team, games: [] });
        for (let j = 0; j < dateRangeArray.length; j++) {
          dateRangeGamesPerTeam[index].games[j] = undefined;
        }
      });
      dateRangeAllGames.forEach((game) => {
        const dateIndex = dateRangeDates.indexOf(game.date);
        const homeTeam = dateRangeGamesPerTeam.find((team) => team.name === game.home);
        const awayTeam = dateRangeGamesPerTeam.find((team) => team.name === game.away);
        if (homeTeam && awayTeam) {
          homeTeam.games[dateIndex] = { against: awayTeam, isAway: false };
          awayTeam.games[dateIndex] = { against: homeTeam, isAway: true };
        }
      });
      setDates(dateRangeArray.map((day) => day.format('DD.MM')));
      setGames(dateRangeGamesPerTeam);
    }
  }, [gameData, from, to]);

  const handleFromChange = (day) => {
    setGames([]);
    const newFrom = moment(day).format(DAY_FORMAT);
    if (moment(newFrom).isBefore(to)) {
      setFrom(newFrom);
    }
  };

  const handleToChange = (day) => {
    setGames([]);
    const newTo = moment(day).format(DAY_FORMAT);
    if (moment(newTo).isAfter(from)) {
      setTo(newTo);
    }
  };

  const toDate = moment(to).toDate();
  const fromDate = moment(from).toDate();
  let gameCalendar = <Spinner />;

  if (games.length > 0) {
    gameCalendar = <GameCalendar games={games} dates={dates} />;
  }

  return (
    <div>
      <h1>Liigan pelikalenteri</h1>
      <ContentWrapper>
        <DateRange fromDate={fromDate} toDate={toDate} onFromDayChange={handleFromChange} onToDayChange={handleToChange} />
        {gameCalendar}
      </ContentWrapper>
    </div>
  );
}

export default Schedule;
