import React, { useState, useEffect } from 'react';
import moment from 'moment';
import axios from 'axios';

function getDailyCacheBustStringByUpdateHour(hour) {
  const DAY_FORMAT = 'YYYYMMDD';
  const today = moment();
  const updatedToday = today.hours() >= hour;
  const updateDate = updatedToday ? today : today.subtract(1, 'days');
  return `${updateDate.format(DAY_FORMAT)}${hour}`;
}

function useGames() {
  const DAILY_GAMES_DATA_UPDATE_HOUR = 14;
  const [gameData, setGameData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  useEffect(() => {
    (async () => {
      setError(false);
      setLoading(true);
      try {
        const { data } = await axios.get(
          `https://liigisapuri.s3.eu-north-1.amazonaws.com/games.json?${getDailyCacheBustStringByUpdateHour(DAILY_GAMES_DATA_UPDATE_HOUR)}`
        );
        setGameData(data);
      } catch (error) {
        setError(error);
      }
      setLoading(false);
    })();
  }, []);

  return { gameData, loading, error };
}

export default useGames;
