import React from 'react';
import styled from 'styled-components';

const Grid = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: bold;
`;

const GridRow = styled.div`
  display: flex;
  flex-direction: row;

  div {
    flex: 0 0 78px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #cccccc;
  }

  :nth-child(even) div {
    background-color: #f2f2f2;
  }
`;

const GridGameRow = styled(GridRow)`
  height: 80px;
`;

const HeaderItem = styled.div`
  background-color: #222;
  color: #f2f2f2;
  padding: 10px 0;
`;

const Logo = styled.span`
  flex: 0 0 40px;
  margin-left: 5px;
`;

function GameCalendar({ games, dates }) {
  const renderHeaderRow = () => (
    <GridRow>
      <HeaderItem>Joukkue</HeaderItem>
      {dates.map((date) => {
        return <HeaderItem key={date}>{date}</HeaderItem>;
      })}
      <HeaderItem>Pelit</HeaderItem>
    </GridRow>
  );

  const renderTeamRows = () =>
    games.map((team, teamIndex) => {
      const totalGamesForTeam = team.games.filter(Boolean).length;
      return (
        <GridGameRow key={team.name}>
          <div>
            <Logo>{team.logo}</Logo>
          </div>
          {team.games.map((game, gameIndex) => {
            if (game) {
              return (
                <div key={`${teamIndex}${gameIndex}`}>
                  <span>{game.isAway ? '@ ' : ''}</span>
                  <Logo>{game.against ? game.against.logo : ''}</Logo>
                </div>
              );
            }
            return <div key={`${teamIndex}${gameIndex}`} />;
          })}
          <div>{totalGamesForTeam}</div>
        </GridGameRow>
      );
    });

  return (
    <Grid>
      {renderHeaderRow()}
      {renderTeamRows()}
    </Grid>
  );
}

export default GameCalendar;
