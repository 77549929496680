import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import styled, { createGlobalStyle } from 'styled-components';
import Nav from './Nav';
import Schedule from './Schedule';
import Lineups from './Lineups';
import useGameData from '../hooks/useGameData';
import HaettenschweilerFontUrl from '../fonts/Haettenschweiler.ttf';

const GlobalStyle = createGlobalStyle`
 @font-face {
      font-family: 'HaettenschweilerLocal';
      src: url(${HaettenschweilerFontUrl});
      font-style: normal;
    }

  body, html {
    margin: 0;
    font-family: 'Trebuchet MS', Arial, Helvetica, sans-serif;
  }
`;

const ContentWrapper = styled.div`
  max-width: 1280px;
  margin: auto;
  overflow-x: auto;
  padding: 20px;
`;

function App() {
  const { gameData, loading, error } = useGameData();

  return (
    <Router>
      <GlobalStyle />
      <Nav />
      <ContentWrapper>
        <Switch>
          <Route path='/kokoonpanot/:team'>
            <Lineups gameData={gameData} />
          </Route>
          <Route path='/kokoonpanot'>
            <Lineups gameData={gameData} />
          </Route>
          <Route path='/pelikalenteri'>
            <Schedule gameData={gameData} />
          </Route>
          <Route path='/'>
            <Redirect to='/pelikalenteri' />
          </Route>
        </Switch>
      </ContentWrapper>
    </Router>
  );
}

export default App;
