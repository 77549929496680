import React from 'react';
import styled from 'styled-components';
import Jersey from './Jersey';

const Wrapper = styled.div`
  margin: 0px 20px;
  text-align: center;
`;

const Player = ({ number, name, team }) => {
  const nameParts = name.split(',');
  const forename = nameParts[1];
  const lastname = nameParts[0];
  return (
    <Wrapper>
      <Jersey
        number={number}
        name={lastname}
        collarColor={team.jersey.collarColor}
        shouldersColor={team.jersey.shouldersColor}
        sleevesColor={team.jersey.sleevesColor}
        topBackColor={team.jersey.topBackColor}
        bottomBackColor={team.jersey.bottomBackColor}
        smallStripesColor={team.jersey.smallStripesColor}
        largeStripesColor={team.jersey.largeStripesColor}
        textColor={team.jersey.textColor}
        textStrokeColor={team.jersey.textStrokeColor}
      />
      {`${forename} ${lastname}`}
    </Wrapper>
  );
};

export default Player;
