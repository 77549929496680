import React from 'react';
import * as logos from '../logos';

const TEAM_ATTRIBUTES = [
  {
    key: 'hifk',
    name: 'HIFK',
    logo: <logos.HIFK />,
    jersey: {
      collarColor: '#DB0D04',
      shouldersColor: '#DB0D04',
      sleevesColor: '#DB0D04',
      topBackColor: '#DB0D04',
      bottomBackColor: '#DB0D04',
      smallStripesColor: '#FFFFFF',
      largeStripesColor: '#0F224F',
      textColor: '#FFFFFF',
      textStrokeColor: '#000000',
    },
  },
  {
    key: 'hpk',
    name: 'HPK',
    logo: <logos.HPK />,
    jersey: {
      collarColor: '#F48219',
      shouldersColor: '#000000',
      sleevesColor: '#F48219',
      topBackColor: '#F48219',
      bottomBackColor: '#F48219',
      smallStripesColor: '#FFFFFF',
      largeStripesColor: '#000000',
      textColor: '#FFFFFF',
      textStrokeColor: '#000000',
    },
  },
  {
    key: 'ilves',
    name: 'Ilves',
    logo: <logos.ILVES />,
    jersey: {
      collarColor: '#FFCB05',
      shouldersColor: '#000000',
      sleevesColor: '#016934',
      topBackColor: '#016934',
      bottomBackColor: '#016934',
      smallStripesColor: '#FFCB05',
      largeStripesColor: '#000000',
      textColor: '#FFCB05',
      textStrokeColor: '#000000',
    },
  },
  {
    key: 'jukurit',
    name: 'Jukurit',
    logo: <logos.JUKURIT />,
    jersey: {
      collarColor: '#FFD400',
      shouldersColor: '#FFFFFF',
      sleevesColor: '#004B80',
      topBackColor: '#004B80',
      bottomBackColor: '#004B80',
      smallStripesColor: '#FFD400',
      largeStripesColor: '#FFFFFF',
      textColor: '#FFD400',
      textStrokeColor: '#000000',
    },
  },
  {
    key: 'jyp',
    name: 'JYP',
    logo: <logos.JYP />,
    jersey: {
      collarColor: '#FFFFFF',
      shouldersColor: '#FFFFFF',
      sleevesColor: '#000000',
      topBackColor: '#000000',
      bottomBackColor: '#000000',
      smallStripesColor: '#ED1C24',
      largeStripesColor: '#FFFFFF',
      textColor: '#FFFFFF',
      textStrokeColor: '#ED1C24',
    },
  },
  {
    key: 'kalpa',
    name: 'KalPa',
    logo: <logos.KALPA />,
    jersey: {
      collarColor: '#FECC00',
      shouldersColor: '#FECC00',
      sleevesColor: '#FECC00',
      topBackColor: '#FECC00',
      bottomBackColor: '#FECC00',
      smallStripesColor: '#FECC00',
      largeStripesColor: '#000000',
      textColor: '#000000',
      textStrokeColor: '#FECC00',
    },
  },
  {
    key: 'k-espoo',
    name: 'K-Espoo',
    logo: <logos.KESPOO />,
    jersey: {
      collarColor: '#FEDD03',
      shouldersColor: '#010033',
      sleevesColor: '#010033',
      topBackColor: '#010033',
      bottomBackColor: '#010033',
      smallStripesColor: '#0076CE',
      largeStripesColor: '#0076CE',
      textColor: '#FFFFFF',
      textStrokeColor: '#000000',
    },
  },
  {
    key: 'karpat',
    name: 'Kärpät',
    logo: <logos.KARPAT />,
    jersey: {
      collarColor: '#000000',
      shouldersColor: '#000000',
      sleevesColor: '#000000',
      topBackColor: '#000000',
      bottomBackColor: '#000000',
      smallStripesColor: '#FFFFFF',
      largeStripesColor: '#FFC938',
      textColor: '#FFFFFF',
      textStrokeColor: '#FFC938',
    },
  },
  {
    key: 'kookoo',
    name: 'KooKoo',
    logo: <logos.KOOKOO />,
    jersey: {
      collarColor: '#000000',
      shouldersColor: '#000000',
      sleevesColor: '#000000',
      topBackColor: '#000000',
      bottomBackColor: '#000000',
      smallStripesColor: '#E97121',
      largeStripesColor: '#E97121',
      textColor: '#E97121',
      textStrokeColor: '#FFFFFF',
    },
  },
  {
    key: 'lukko',
    name: 'Lukko',
    logo: <logos.LUKKO />,
    jersey: {
      collarColor: '#004187',
      shouldersColor: '#004187',
      sleevesColor: '#004187',
      topBackColor: '#004187',
      bottomBackColor: '#004187',
      smallStripesColor: '#FFD300',
      largeStripesColor: '#FFD300',
      textColor: '#FFD300',
      textStrokeColor: '#000000',
    },
  },
  {
    key: 'pelicans',
    name: 'Pelicans',
    logo: <logos.PELICANS />,
    jersey: {
      collarColor: '#1EC3F3',
      shouldersColor: '#000000',
      sleevesColor: '#1EC3F3',
      topBackColor: '#1EC3F3',
      bottomBackColor: '#000000',
      smallStripesColor: '#000000',
      largeStripesColor: '#FFFFFF',
      textColor: '#FFFFFF',
      textStrokeColor: '#000000',
    },
  },
  {
    key: 'saipa',
    name: 'SaiPa',
    logo: <logos.SAIPA />,
    jersey: {
      collarColor: '#000000',
      shouldersColor: '#FFE100',
      sleevesColor: '#FFE100',
      topBackColor: '#FFE100',
      bottomBackColor: '#000000',
      smallStripesColor: '#000000',
      largeStripesColor: '#FFE100',
      textColor: '#000000',
      textStrokeColor: '#FFE100',
    },
  },
  {
    key: 'sport',
    name: 'Sport',
    logo: <logos.SPORT />,
    jersey: {
      collarColor: '#FFFFFF',
      shouldersColor: '#E00614',
      sleevesColor: '#E00614',
      topBackColor: '#E00614',
      bottomBackColor: '#E00614',
      smallStripesColor: '#FFFFFF',
      largeStripesColor: '#FFFFFF',
      textColor: '#FFFFFF',
      textStrokeColor: '#000000',
    },
  },
  {
    key: 'tappara',
    name: 'Tappara',
    logo: <logos.TAPPARA />,
    jersey: {
      collarColor: '#0C2576',
      shouldersColor: '#FF7900',
      sleevesColor: '#0C2576',
      topBackColor: '#0C2576',
      bottomBackColor: '#0C2576',
      smallStripesColor: '#FFFFFF',
      largeStripesColor: '#FF7900',
      textColor: '#FF7900',
      textStrokeColor: '#FFFFFF',
    },
  },
  {
    key: 'tps',
    name: 'TPS',
    logo: <logos.TPS />,
    jersey: {
      collarColor: '#FFFFFF',
      shouldersColor: '#000000',
      sleevesColor: '#000000',
      topBackColor: '#000000',
      bottomBackColor: '#000000',
      smallStripesColor: '#FFFFFF',
      largeStripesColor: '#000000',
      textColor: '#FFFFFF',
      textStrokeColor: '#000000',
    },
  },
  {
    key: 'assat',
    name: 'Ässät',
    logo: <logos.ASSAT />,
    jersey: {
      collarColor: '#000000',
      shouldersColor: '#000000',
      sleevesColor: '#BA1019',
      topBackColor: '#BA1019',
      bottomBackColor: '#BA1019',
      smallStripesColor: '#FFFFFF',
      largeStripesColor: '#000000',
      textColor: '#000000',
      textStrokeColor: '#FFFFFF',
    },
  },
];

export default TEAM_ATTRIBUTES;
