import React from 'react';
import styled from 'styled-components';
import { Link, NavLink } from 'react-router-dom';

const TopNav = styled.nav`
  overflow: hidden;
  background-color: #333;
  display: flex;
  align-items: center;
  color: #f2f2f2;
`;

const NavLinkWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 20px;
  width: 100%;

  * {
    margin-left: 32px;
    color: grey;
    text-decoration: none;
    font-size: 20px;
    position: relative;
    top: 6px;
  }
  a:hover {
    color: white;
  }
  a.active {
    color: #f2f2f2;
  }
`;

const Title = styled(Link)`
  color: #f2f2f2;
  text-decoration: none;
  font-family: 'HaettenschweilerLocal';
  font-size: 42px;
  padding: 16px;
`;

const Email = styled.a`
  margin-left: auto;
  padding-right: 16px;
`;
function Nav() {
  return (
    <TopNav>
      <Title to='/'>vaihtovirhe.fi</Title>
      <NavLinkWrapper>
        <NavLink activeClassName='active' to='/pelikalenteri'>
          Pelikalenteri
        </NavLink>
        <NavLink activeClassName='active' to='/kokoonpanot'>
          Kokoonpanot
        </NavLink>
        <Email href='mailto:vaihtovirhe@gmail.com'>Lähetä viesti</Email>
      </NavLinkWrapper>
    </TopNav>
  );
}

export default Nav;
