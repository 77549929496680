import React, { useRef } from 'react';
import { formatDate, parseDate } from 'react-day-picker/moment';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import '../styles/day-picker-style.css';

const DateRange = ({ fromDate, toDate, onFromDayChange, onToDayChange }) => {
  const toEl = useRef(null);
  const modifiers = { start: fromDate, end: toDate };

  const MONTHS = ['Tammikuu', 'Helmikuu', 'Maaliskuu', 'Huhtikuu', 'Toukokuu', 'Kesäkuu', 'Heinäkuu', 'Elokuu', 'Syyskuu', 'Lokakuu', 'Marraskuu', 'Joulukuu'];
  const WEEKDAYS_LONG = ['Sunnuntai', 'Maanantai', 'Tiistai', 'Keskiviikko', 'Torstai', 'Perjantai', 'Lauantai'];
  const WEEKDAYS_SHORT = ['Su', 'Ma', 'Ti', 'Ke', 'To', 'Pe', 'La'];

  return (
    <div>
      <DayPickerInput
        value={fromDate}
        format='DD.MM.YYYY'
        formatDate={formatDate}
        parseDate={parseDate}
        onDayChange={onFromDayChange}
        dayPickerProps={{
          selectedDays: [fromDate, { from: fromDate, to: toDate }],
          disabledDays: { after: toDate },
          toMonth: toDate,
          modifiers,
          locale: 'fi',
          months: MONTHS,
          weekdaysLong: WEEKDAYS_LONG,
          weekdaysShort: WEEKDAYS_SHORT,
          firstDayOfWeek: 1,
          onDayClick: () => toEl.current.getInput().focus(),
        }}
      />
      {' — '}
      <DayPickerInput
        ref={toEl}
        value={toDate}
        format='DD.MM.YYYY'
        formatDate={formatDate}
        parseDate={parseDate}
        onDayChange={onToDayChange}
        dayPickerProps={{
          selectedDays: [fromDate, { from: fromDate, to: toDate }],
          disabledDays: { before: fromDate },
          modifiers,
          month: fromDate,
          fromMonth: fromDate,
          locale: 'fi',
          months: MONTHS,
          weekdaysLong: WEEKDAYS_LONG,
          weekdaysShort: WEEKDAYS_SHORT,
          firstDayOfWeek: 1,
        }}
      />
    </div>
  );
};

export default DateRange;
